import { useEffect } from "react";
import classes from "./Dummy.module.css";

export default function Dummy(params) {
  useEffect(() => {
    window.addEventListener("reload", () => {});
  }, []);
  return (
    <div className={classes.dummy}>
      <div className="container">
        <div className={classes.paraGraph}>
          <p>The Page is Under Construction...</p>
        </div>
      </div>
    </div>
  );
}
