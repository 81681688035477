import { useHistory } from "react-router";
import { scrollToRef } from "../../../../../methods/scrollTo/scrollTo";
import classes from "./NavSubItemView.module.css";

export default function NavSubItemView({ item, closeHover }) {
  const history = useHistory();

  const handleLink = (item) => {
    if (item.link) {
      history.push(item.link);
    } else if (item.url) {
      scrollToRef(item.url);
    }
    closeHover();
  };

  return (
    <div className={classes.navSubItemView}>
      <h6 onClick={() => handleLink(item)}>{item.title}</h6>
      {/* {item.subList && (
        <div className={classes.subListContainer}>
         
        </div>
      )} */}
    </div>
  );
}
