import Button from "../../../../components/button/Button";
import classes from "./AboutUs.module.css";

export default function AboutUs({ image }) {
  return (
    <div className={classes.aboutUs}>
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-6">
            <div className={classes.leftPart}>
              <h2>ABOUT US</h2>
              <div className="pe-0 pe-lg-4">
                <p>TN Sourcing is a prominent readymade garment sourcing and exporter with a vision to deliver world-class service, quality solutions, and value-added services to end products for the textile industries on a global level, beginning from fabrics to fashion through market knowledge, experienced sourcing professionals.</p>
              </div>
              <div className={"mb-5 pb-lg-0 " + classes.btnContainer}>
                <Button title="READ MORE" />
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-6">
            <div className={classes.rightPart}>
              <div className={classes.image}>
                <img src={image} alt="img" />
              </div>
              <div className="w-100">
                <div className={classes.block}></div>
                <div className={classes.border}></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
