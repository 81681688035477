import classes from "./Navbar.module.css";

import Modal from "../../../methods/fullScreenModal/Modal";
import { useRef, useState } from "react";
import ListItems from "../../../methods/listItems/listItems";
import Menubar from "./parts/menubar/Menubar";
import LogoList from "./view/logoList/LogoList";
import NavItemView from "./view/navItemView/NavItemView";
import SidebarView from "./parts/sidebar/Sidebar";
import logo from "./../../../data/assets/TN Sourcing Logo.png";
import { navItems } from "../../../data/data";

export default function Navbar(params) {
  const [show, setShow] = useState(false);
  const navRef = useRef();

  return (
    <div ref={navRef} className={classes.navbar}>
      {show && <Modal show={show} setShow={setShow} View={SidebarView} />}

      <div className="container">
        <div className={classes.nav}>
          <div className={classes.brandLogo}>
            <img className="" src={logo} alt="Logo" />
          </div>
          <div className={"d-block d-lg-none " + classes.logoListContainer}>
            <LogoList />
          </div>
          <div className=" d-flex d-lg-none">
            <div onClick={() => setShow(!show)} className={classes.menubar}>
              <i className="fas fa-bars"></i>
            </div>
          </div>
          <div className={"d-none d-lg-flex flex-column justify-content-between " + classes.navRight}>
            <div className="d-none d-lg-block">
              <Menubar />
            </div>
            <div className={classes.menuContainer}>
              <ListItems list={navItems} View={NavItemView} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
