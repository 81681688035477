import { useState } from "react";
import { useHistory } from "react-router";
import ListItems from "../../../../../../../methods/listItems/listItems";
import { scrollToRef } from "../../../../../../../methods/scrollTo/scrollTo";
import SubListView from "../subListView/SubListView";
import classes from "./ListView.module.css";

export default function ListView({ item, modalClose }) {
  const [expandedList, updateExpandedList] = useState([]);
  const history = useHistory();

  const handleExpansion = (id) => {
    if (expandedList.includes(id)) {
      updateExpandedList(() => expandedList.filter((i) => i !== id));
    } else {
      updateExpandedList([...expandedList, id]);
    }
  };

  const handleLink = (item) => {
    if (item.link) {
      history.push(item.link);
    } else if (item.url) {
      history.push("/");
      setTimeout(() => {
        scrollToRef(item.url);
      }, 100);
    }
    modalClose();
  };

  return (
    <>
      {item.subList === undefined ? (
        <div onClick={() => handleLink(item)} className={classes.sidebarListView}>
          <h4 key={item.id}>{item.title}</h4>
        </div>
      ) : (
        <>
          <div onClick={() => handleExpansion(item.id)} className={classes.sidebarListView}>
            <div className={classes.svgIcon + " " + (expandedList.includes(item.id) && classes.rotate)}>
              <i className="fas fa-chevron-down fa-xs"></i>
            </div>
            <h4 key={item.id}>{item.title}</h4>
          </div>
          {expandedList.includes(item.id) && (
            <div className={classes.subList}>
              <ListItems list={item.subList} column View={(props) => <SubListView {...props} modalClose={modalClose} />} />
            </div>
          )}
        </>
      )}
    </>
  );
}
