import classes from "./listItems.module.css";

export default function ListItems({ list, View, column }) {
  return (
    <div className={classes.list}>
      {column ? (
        <div className={classes.listContainer + " " + classes.column}>
          {list?.map((li) => {
            return <View key={li.id} item={li} />;
          })}
        </div>
      ) : (
        <div className={classes.listContainer}>
          {list?.map((li) => {
            return <View key={li.id} item={li} />;
          })}
        </div>
      )}
    </div>
  );
}
