import { Switch } from "react-router";
import { Route } from "react-router-dom";
import DummyPage from "../../../pages/dummy/Dummy";
import HomePage from "../../../pages/home/Home";
import Kid from "../../../pages/kid/Kid";
import Men from "../../../pages/men/Men";
import Women from "../../../pages/women/Women";

export default function Routing(params) {
  return (
    <Switch>
      <Route exact path="/" component={HomePage} />
      <Route path="/category/men" component={Men} />
      <Route path="/category/women" component={Women} />
      <Route path="/category/kid" component={Kid} />

      <Route path="/dummyPage" component={DummyPage} />
    </Switch>
  );
}
