import { useState } from "react";
import { useHistory } from "react-router";
import ListItems from "../../../../../methods/listItems/listItems";
import { scrollToRef } from "../../../../../methods/scrollTo/scrollTo";
import NavSubItemView from "../navSubItemView/NavSubItemView";
import classes from "./NavItemView.module.css";

export default function NavItemView({ item }) {
  const [hoverId, setHoverId] = useState(null);
  // const subRef = useRef(activeItem);
  const history = useHistory();

  const handleLink = (item) => {
    if (item.link) {
      history.push(item.link);
    } else if (item.url) {
      history.push("/");
      setTimeout(() => {
        scrollToRef(item.url);
      }, 100);
    }
    setHoverId(null);
  };

  const controlHover = (id) => {
    if (id === hoverId) {
      setHoverId(null);
    }
  };
  return (
    <div className={classes.navItemView}>
      {item.subList === undefined ? (
        <h5 onClick={() => handleLink(item)}>{item.title}</h5>
      ) : (
        <div onMouseEnter={() => setHoverId(item.id)} onMouseLeave={() => controlHover(item.id)} className={"position-relative " + classes.group}>
          <h5>{item.title}</h5>
          <div className={classes.subList + " " + (item.id === hoverId && classes.show)}>
            <div className={classes.subListContainer}>
              <ListItems list={item.subList} View={(props) => <NavSubItemView {...props} closeHover={() => setHoverId(null)} />} column />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
