import classes from "./Layout.module.css";
import Footer from "./parts/footer/Footer";
import Navbar from "./parts/navbar/Navbar";
import Routing from "./parts/routing/Routing";

export default function Layout(params) {
  return (
    <div className={classes.Layout}>
      <Navbar />
      <div className={classes.main}>
        <Routing />
      </div>
      <Footer />
    </div>
  );
}
