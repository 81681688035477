import classes from "./OurMission.module.css";

export default function OurMission({ data }) {
  return (
    <div className={classes.ourMission}>
      <div className="container">
        <div className={classes.wrapper}>
          <div className="row">
            <div className="col-12 col-lg-6 h-100 overflow-auto d-flex align-items-center">
              <div className={classes.leftPart}>
                <h2 className="">Our Vision:</h2>
                <p className="lh-sm">To be referred as the most favorable company in the trading industry</p>
                <h2 className="mt-3">Our Mission:</h2>
                <p className="lh-sm">Our mission is to source top-quality garments and supply to the respected buyers.</p>
                <div className={classes.listContainer}>
                  {data.missions?.map((ul) => (
                    <ul key={ul.id} className="list-style-none d-flex align-items-start lh-sm mb-2">
                      <li className="">
                        <i className="fas fa-asterisk"></i>
                      </li>
                      <li>{ul.title}</li>
                    </ul>
                  ))}
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6 ">
              <div className={classes.rightPart}>
                <img src={data.image} alt="images" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
