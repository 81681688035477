import { useHistory } from "react-router";
import { scrollToRef } from "../../../../../../../methods/scrollTo/scrollTo";
import classes from "./SubListView.module.css";

export default function SubListView({ item, modalClose }) {
  const history = useHistory();
  const handleLink = (item) => {
    if (item.link) {
      history.push(item.link);
    } else if (item.url) {
      history.push("/");
      setTimeout(() => {
        scrollToRef(item.url);
      }, 100);
    }
    modalClose();
  };
  return (
    <>
      <div onClick={() => handleLink(item)} className={classes.subListView}>
        <h5 key={item.id}>{item.title}</h5>
      </div>
    </>
  );
}
