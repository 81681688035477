import m1 from "./assets/men/m1.jpg";
import m2 from "./assets/men/m2.jpg";
import m3 from "./assets/men/m3.jpeg";
import m4 from "./assets/men/m4.jpg";
import m5 from "./assets/men/m5.jpg";
import m6 from "./assets/men/m6.jpg";
import m7 from "./assets/men/m7.jpeg";
import m8 from "./assets/men/m8.jpg";

import w1 from "./assets/women/w1.png";
import w2 from "./assets/women/w2.jpg";
import w3 from "./assets/women/w1.png";
import w4 from "./assets/women/w4.jpg";
import w5 from "./assets/women/w5.jpg";
import w6 from "./assets/women/w6.jpg";
import w7 from "./assets/women/w7.jpeg";
import w8 from "./assets/women/w8.jpg";

import k1 from "./assets/kid/k1.jpg";
import k2 from "./assets/kid/k2.jpeg";
import k3 from "./assets/kid/k3.jpg";
import k4 from "./assets/kid/k4.jpg";
import k5 from "./assets/kid/k5.jpg";
import k6 from "./assets/kid/k6.jpeg";
import k7 from "./assets/kid/k7.jpg";

export const navItems = [
  {
    id: 1,
    title: "HOME",
    link: "/",
  },
  {
    id: 2,
    title: "MEN",
    link: "/category/men",
  },
  {
    id: 3,
    title: "WOMEN",
    link: "/category/women",
  },
  {
    id: 4,
    title: "KIDS",
    link: "/category/kid",
  },
  {
    id: 5,
    title: "COLLECTION",
    link: "dummyPage",
  },
  {
    id: 6,
    title: "FABRICS",
    link: "dummyPage",
  },
];

export const products = [
  { id: 1, title: "", description: "", category: "top wear", useFor: "men", image: m1 },
  { id: 2, title: "", description: "", category: "bottom wear", useFor: "men", image: m2 },
  { id: 3, title: "", description: "", category: "lounge wear", useFor: "men", image: m3 },
  { id: 4, title: "", description: "", category: "active wear", useFor: "men", image: m4 },
  { id: 5, title: "", description: "", category: "masks", useFor: "men", image: m5 },
  { id: 6, title: "", description: "", category: "top wear", useFor: "men", image: m6 },
  { id: 7, title: "", description: "", category: "bottom wear", useFor: "men", image: m7 },
  { id: 8, title: "", description: "", category: "lounge wear", useFor: "men", image: m8 },
  { id: 9, title: "", description: "", category: "active wear", useFor: "men", image: m1 },
  { id: 10, title: "", description: "", category: "masks", useFor: "men", image: m2 },
  { id: 11, title: "", description: "", category: "top wear", useFor: "women", image: w1 },
  { id: 12, title: "", description: "", category: "bottom wear", useFor: "women", image: w2 },
  { id: 13, title: "", description: "", category: "lounge wear", useFor: "women", image: w3 },
  { id: 14, title: "", description: "", category: "active wear", useFor: "women", image: w4 },
  { id: 15, title: "", description: "", category: "masks", useFor: "women", image: w5 },
  { id: 16, title: "", description: "", category: "top wear", useFor: "women", image: w6 },
  { id: 17, title: "", description: "", category: "bottom wear", useFor: "women", image: w7 },
  { id: 18, title: "", description: "", category: "lounge wear", useFor: "women", image: w8 },
  { id: 19, title: "", description: "", category: "active wear", useFor: "women", image: w1 },
  { id: 20, title: "", description: "", category: "masks", useFor: "women", image: w2 },
  { id: 21, title: "", description: "", category: "top wear", useFor: "men", image: m1 },
  { id: 22, title: "", description: "", category: "bottom wear", useFor: "men", image: m2 },
  { id: 23, title: "", description: "", category: "lounge wear", useFor: "men", image: m3 },
  { id: 24, title: "", description: "", category: "active wear", useFor: "men", image: m4 },
  { id: 25, title: "", description: "", category: "masks", useFor: "men", image: m5 },
  { id: 26, title: "", description: "", category: "top wear", useFor: "men", image: m6 },
  { id: 27, title: "", description: "", category: "bottom wear", useFor: "men", image: m7 },
  { id: 28, title: "", description: "", category: "lounge wear", useFor: "men", image: m8 },
  { id: 29, title: "", description: "", category: "active wear", useFor: "men", image: m1 },
  { id: 30, title: "", description: "", category: "masks", useFor: "men", image: m2 },
  { id: 31, title: "", description: "", category: "top wear", useFor: "women", image: w1 },
  { id: 32, title: "", description: "", category: "bottom wear", useFor: "women", image: w2 },
  { id: 33, title: "", description: "", category: "lounge wear", useFor: "women", image: w3 },
  { id: 34, title: "", description: "", category: "active wear", useFor: "women", image: w4 },
  { id: 35, title: "", description: "", category: "masks", useFor: "women", image: w5 },
  { id: 36, title: "", description: "", category: "top wear", useFor: "women", image: w6 },
  { id: 37, title: "", description: "", category: "bottom wear", useFor: "women", image: w7 },
  { id: 38, title: "", description: "", category: "lounge wear", useFor: "women", image: w8 },
  { id: 39, title: "", description: "", category: "active wear", useFor: "women", image: w1 },
  { id: 40, title: "", description: "", category: "masks", useFor: "women", image: w2 },
  { id: 41, title: "", description: "", category: "top wear", useFor: "kid", image: k1 },
  { id: 42, title: "", description: "", category: "bottom wear", useFor: "kid", image: k2 },
  { id: 43, title: "", description: "", category: "lounge wear", useFor: "kid", image: k3 },
  { id: 44, title: "", description: "", category: "active wear", useFor: "kid", image: k4 },
  { id: 45, title: "", description: "", category: "masks", useFor: "kid", image: k5 },
  { id: 46, title: "", description: "", category: "top wear", useFor: "kid", image: k6 },
  { id: 47, title: "", description: "", category: "bottom wear", useFor: "kid", image: k7 },
  { id: 48, title: "", description: "", category: "lounge wear", useFor: "kid", image: k3 },
  { id: 49, title: "", description: "", category: "active wear", useFor: "kid", image: k1 },
  { id: 50, title: "", description: "", category: "masks", useFor: "kid", image: k2 },
  { id: 51, title: "", description: "", category: "top wear", useFor: "kid", image: k1 },
  { id: 52, title: "", description: "", category: "bottom wear", useFor: "kid", image: w2 },
  { id: 53, title: "", description: "", category: "lounge wear", useFor: "kid", image: k3 },
  { id: 54, title: "", description: "", category: "active wear", useFor: "kid", image: k4 },
  { id: 55, title: "", description: "", category: "masks", useFor: "kid", image: k5 },
  { id: 56, title: "", description: "", category: "top wear", useFor: "kid", image: k6 },
  { id: 57, title: "", description: "", category: "bottom wear", useFor: "kid", image: k7 },
  { id: 58, title: "", description: "", category: "lounge wear", useFor: "kid", image: k2 },
  { id: 59, title: "", description: "", category: "active wear", useFor: "kid", image: k1 },
  { id: 60, title: "", description: "", category: "masks", useFor: "kid", image: k2 },
];
