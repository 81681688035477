export const listData = [
  {
    id: 1,
    title: (
      <div>
        <p className="">
          House # 19, Road # 1, Nikunja-2 <br />
          Dhaka-1229, Bangladesh <span>(Mailing Address)</span>
        </p>
        <p className="mt-1">
          529/1, Noyatola, Moghbazar, <br />
          Dhaka-1217, Bangladesh <span>(Registered Address)</span>
        </p>
      </div>
    ),
    icon: <i className="fas fa-map-marker-alt"></i>,
  },
  {
    id: 2,
    title: (
      <div>
        <p className="">+8801713301419</p>
        <p className="">+8801749100394</p>
      </div>
    ),
    icon: <i className="fas fa-phone-alt fa-sm"></i>,
  },
  {
    id: 3,
    title: (
      <div>
        <p className="">turan@tn-sourcing.com</p>
        <p className="">nazmul@tn-sourcing.com</p>
      </div>
    ),
    icon: <i className="far fa-envelope"></i>,
  },
];
