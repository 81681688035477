import classes from "./Menubar.module.css";
import LogoList from "../../view/logoList/LogoList";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export default function Menubar(params) {
  const history = useHistory();
  return (
    <div className={classes.menubar} id="navbarId">
      <div className={classes.menuContainer}>
        <div className="d-flex align-items-start ">
          <ul className="d-flex  align-items-start">
            <li className={classes.iconPhone}>
              <i className="fas fa-phone-alt"></i>
            </li>
            <p className="ms-1 d-flex flex-column">
              <span>+8801713301419</span>
              <span>+8801749100394</span>
            </p>
          </ul>
          <ul onClick={() => history.push("dummypage")} className="ms-5 d-flex flex-grow-1 align-items-center pointer">
            <i className="far fa-envelope"></i>
            <p className="ms-1">turan@tn-sourcing.com</p>
          </ul>
          <ul className={classes.logoList}>
            <LogoList />
          </ul>
        </div>
      </div>
    </div>
  );
}
