import mc1 from "./assets/mc1.jpg";
import mc2 from "./assets/mc2.webp";
import mc3 from "./assets/mc3.jpg";
import mc4 from "./assets/mc4.jpg";
import mc5 from "./assets/mc5.jpeg";

export const menCategories = [
  { id: 1, title: "TOP WEAR", image: mc1 },
  { id: 2, title: "BOTTOM WEAR", image: mc2 },
  { id: 3, title: "LOUNGE WEAR", image: mc3 },
  { id: 4, title: "ACTIVE WEAR", image: mc4 },
  { id: 5, title: "MASKS", image: mc5 },
];
