export default function Banner({ image, title, viewRight }) {
  return (
    <div className="w-100 position-relative">
      <img src={image} alt="bg" width="100%" height="auto" />
      <div className="w-100 h-100 position-absolute top-0 left-0">
        <div className={"container h-100 d-flex align-items-center " + (viewRight && "justify-content-end")}>{title && title}</div>
      </div>
    </div>
  );
}
