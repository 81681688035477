import classes from "./OriginalProducts.module.css";

import Button from "../../../../components/button/Button";

export default function OriginalProducts({ pic01, pic02 }) {
  return (
    <div className={classes.originalProducts}>
      <div className={classes.wrapper}>
        <div className={classes.content}>
          <div className="container">
            <div className="row">
              <div className="col-12 col-lg-6 ">
                <div className={classes.leftPart}>
                  <div className="px-5 d-flex align-items-center h-100">
                    <img src={pic01} alt="" />
                  </div>
                </div>
              </div>

              <div className="col-12 col-lg-6">
                <div className={classes.rightPart}>
                  <div className="">
                    <h2 className="px-2 px-lg-0">Our core values</h2>
                    <p className="px-2 px-lg-0">
                      We are passionate in helping our customers succeed. By developing a deep understanding of their brands, we seek to provide the most updated trend forecasts or co-create truly innovative and collaborative collections with the right quality and price for our customers. In all that time we have gained a loyal following. Our garments are designed for a laid-back, but stylish individual. We don’t care about dress codes; all we care about is being comfortable and looking good. Our loyal customers have realized that it’s not only how you look, but how you feel that makes you comfortable. Come find out why millions of young men continue to wearour product year after year. We are socially responsible manufacturer, who abide by laws and regulations, care for the environment and
                      contribute back to the community we serve.
                    </p>
                    <div className={classes.btnContainer}>
                      <Button title="READ MORE" />
                    </div>
                    <div className="px-5">
                      <img src={pic02} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
