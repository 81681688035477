import wc1 from "./assets/wc1.jpg";
import wc2 from "./assets/wc2.jpeg";
import wc3 from "./assets/wc3.png";
import wc4 from "./assets/wc4.png";
import wc5 from "./assets/wc5.png";

export const womenCategories = [
  { id: 1, title: "TOP WEAR", image: wc1 },
  { id: 2, title: "BOTTOM WEAR", image: wc2 },
  { id: 3, title: "LOUNGE WEAR", image: wc3 },
  { id: 4, title: "ACTIVE WEAR", image: wc4 },
  { id: 5, title: "MASKS", image: wc5 },
];
