import Button from "../../../../../components/button/Button";
import classes from "./Account.module.css";

export default function Account(params) {
  return (
    <div className={classes.account}>
      <div className={classes.btn1Container}>
        <Button title="Login" />
      </div>
      <div className={classes.btn2Container}>
        <Button title="Register" />
      </div>
    </div>
  );
}
