import classes from "./LogoList.module.css";

export default function LogoList(params) {
  return (
    <div className={classes.logoList}>
      <div className={classes.navIcons}>
        <i className="fas fa-search"></i>
      </div>
      <div className={classes.navIcons}>
        <i className="fab fa-opencart"></i>
      </div>
      <div className={classes.navIcons}>
        <i className="fas fa-user-circle"></i>
      </div>
    </div>
  );
}
