import { listData } from "./dummyData/listData";
import classes from "./Footer.module.css";
import pic1 from "./assets/01.jpg";
import pic2 from "./assets/02.jpg";
import pic3 from "./assets/03.jpg";
import pic4 from "./assets/04.jpg";
import logo from "./assets/TNLogo.png";

export default function Footer(params) {
  return (
    <div className={classes.footer}>
      <div className="container">
        <div className="row">
          <div className="py-4 py-lg-0   col-12 col-lg-5">
            <div className={classes.logo}>
              <img src={logo} alt="logo" />
            </div>
            <ul className={classes.listGroup}>
              {listData?.map((ul) => (
                <ul className="mb-2 d-flex align-items-start lh-sm" key={ul.id}>
                  <span>{ul.icon}</span>
                  {ul.title}
                </ul>
              ))}
            </ul>
          </div>
          <div className="py-4 py-lg-0  p col-12 col-lg-2 d-flex align-items-center">
            <div className={classes.images}>
              <div className="row ">
                <div className="col-6">
                  <img src={pic1} alt="img" />
                </div>
                <div className="col-6">
                  <img src={pic2} alt="img" />
                </div>
                <div className="col-6">
                  <img src={pic3} alt="img" />
                </div>
                <div className="col-6">
                  <img src={pic4} alt="img" />
                </div>
              </div>
            </div>
          </div>
          <div className="py-4 py-lg-0   col-12 col-lg-5">
            <div className="d-flex flex-column align-items-center align-items-lg-end">
              <h4 className=" pb-4 text-black op-7">LET'S KEEP IN TOUCH</h4>
              <div>
                <div className={classes.subscribe}>
                  <input type="text" placeholder=" &#xf0e0;" />
                  <h6>SUBSCRIBE</h6>
                </div>
                <div className="py-2 d-flex align-items-center">
                  <input type="checkbox" />
                  <p className="ms-2">
                    i've read and accept TN Sourcing's <span>Privacy Policy</span>
                  </p>
                </div>
              </div>
              <div className={classes.social}>
                <div>
                  <i className="fab fa-facebook-f"></i>
                </div>
                <div>
                  <i className="fab fa-twitter"></i>
                </div>
                <div>
                  <i className="fab fa-instagram"></i>
                </div>
                <div>
                  <i className="fab fa-linkedin-in"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={classes.copyWrite}>
        <p>
          <span className="me-2">
            <i className="far fa-copyright"></i>
          </span>
          TN Sourcing. All Rights Reserved.
        </p>
      </div>
    </div>
  );
}
