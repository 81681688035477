import { useEffect, useRef } from "react";
import ListItems from "../../../../../methods/listItems/listItems";
import Account from "../account/Account";
import ListView from "./view/listView/ListView";
import classes from "./Sidebar.module.css";
import { navItems } from "../../../../../data/data";

export default function Sidebar({ modalClose }) {
  const posRef = useRef();

  useEffect(() => {
    posRef.current.style.right = 0;
    // posRef.current.style.transition = "right 1s";
  });

  return (
    <div ref={posRef} className={classes.sidebar}>
      <div className="mb-4 px-4 d-flex align-items-center justify-content-end">
        {/* <Cloud /> */}
        <div onClick={modalClose} className={classes.crossIcon}>
          <i className="fas fa-times"></i>
        </div>
      </div>

      <div className={classes.listContainer}>
        <ListItems list={navItems} View={(props) => <ListView {...props} modalClose={modalClose} />} column />
      </div>

      <div className="mt-5 center">
        <Account />
      </div>
    </div>
  );
}
