import classes from "./DisplayCarts.module.css";
export default function DisplayCarts({ items, View, activeCat, onClicked, center, start }) {
  return (
    <div className={classes.displayCart + (center ? " justify-content-center" : "") + (start ? " justify-content-center justify-content-md-start" : " ")}>
      {items.map((item) => (
        <View key={item.id} item={item} onClicked={() => onClicked && onClicked(item)} activeItem={activeCat && activeCat} />
      ))}
    </div>
  );
}
