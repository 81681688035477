import latestImage from "../data/assets/latest.jpg";
const missionList = [
  {
    id: 1,
    title: "Striving to meet the increasing and diversified demands of the customers.",
  },
  {
    id: 2,
    title: "We provides the solution to our clients' most fundamental need:  To elevate their product, margins, and sell-through performance at retail.",
  },
  {
    id: 3,
    title: "Being market leaders in defend market segments.",
  },
  {
    id: 4,
    title: "roviding quality products & reliable services and sourcing from reputed manufacturers.",
  },
  {
    id: 5,
    title: "Becoming professional marketing partners for our principals to meet the objectives of sales, market information, and customer service",
  },
  {
    id: 6,
    title: "Being the preferred supplier of our customers, with our products exceeding their quality requirements.",
  },
  {
    id: 7,
    title: "Harnessing the creative energies of all our people through team work, develop and a transparent work environment.",
  },
];

export const latestTrend = {
  missions: missionList || [],
  image: latestImage,
};
