import { useState, useEffect } from "react";
import Banner from "../../methods/banner/Banner";
import classes from "./Men.module.css";
import menBack from "./data/assets/menBack.jpg";
import { menCategories } from "./data/data";
import DisplayCarts from "../../methods/displayCarts/DisplayCarts";
import CategoryCart from "../../components/carts/category/CategoryCart";
import { productByUseForCat } from "../../api/product/productApi";
import ProductCart from "../../components/carts/product/ProductCart";

export default function Men(params) {
  const [activeCat, setActiveCat] = useState(menCategories[0]);
  const [displayProducts, updateDisplayProducts] = useState([]);

  const bannerTextView = (
    <h1 className="text-black">
      BE <br />
      TRENDY
    </h1>
  );

  useEffect(() => {
    let products = productByUseForCat("men", activeCat.title);
    updateDisplayProducts(products);
  }, [activeCat]);

  return (
    <div className={classes.men}>
      <div>
        <div>
          <Banner image={menBack} title={bannerTextView} />
        </div>

        <div className={classes.categories + " container my-5"}>
          <DisplayCarts onClicked={(parm) => setActiveCat(parm)} items={menCategories} View={CategoryCart} activeCat={activeCat} />
        </div>

        <div className={classes.product + " container my-5"}>
          <DisplayCarts items={displayProducts} View={ProductCart} />
        </div>
      </div>
    </div>
  );
}
