import classes from "./CategoryCart.module.css";

export default function CategoryCart({ item, activeItem, onClicked }) {
  return (
    <div onClick={onClicked && onClicked} className={classes.categoryCart + " " + (activeItem && (item.id === activeItem.id ? classes.active : ""))}>
      <div className={classes.imgWrapper}>
        <img src={item.image} alt="cartImage" />
      </div>
      {item.title && <div className={classes.title}>{item.title}</div>}
    </div>
  );
}
