import kc1 from "./assets/kc1.jpg";
import kc2 from "./assets/kc2.jpg";
import kc3 from "./assets/kc3.webp";
import kc4 from "./assets/kc4.jpeg";
import kc5 from "./assets/kc5.jpg";

export const kidCategories = [
  { id: 1, title: "TOP WEAR", image: kc1 },
  { id: 2, title: "BOTTOM WEAR", image: kc2 },
  { id: 3, title: "LOUNGE WEAR", image: kc3 },
  { id: 4, title: "ACTIVE WEAR", image: kc4 },
  { id: 5, title: "MASKS", image: kc5 },
];
