import classes from "./Home.module.css";
import AboutUs from "./parts/aboutUs/AboutUs";
import LatestTrend from "./parts/ourMission/OurMission";
import OriginalProducts from "./parts/originalProducts/OriginalProducts";
import ProductShow from "./parts/productSlide/ProductShow";
import aboutImage from "./data/assets/about.jpg";
import origin01 from "./data/assets/original01.jpg";
import origin02 from "./data/assets/original02.jpg";
import { latestTrend } from "./data/data";
import bannerImg from "./data/assets/banner.jpg";
import Banner from "../../methods/banner/Banner";

export default function Home(params) {
  const bannerTextView = (
    <h1 className="text-white">
      BE <br />
      TRENDY
    </h1>
  );

  return (
    <div className={classes.home}>
      <div>
        <Banner image={bannerImg} title={bannerTextView} />
      </div>

      <div>
        <LatestTrend data={latestTrend} />
      </div>
      <div>
        <OriginalProducts pic01={origin01} pic02={origin02} />
      </div>
      <div className={classes.productSlideContainer}>
        <div className="container py-5">
          <ProductShow />
        </div>
      </div>
      <div>
        <AboutUs image={aboutImage} />
      </div>
    </div>
  );
}
