import { products } from "../../data/data";

export function productById(id) {}

export function productByCat(category) {}
export function productByIdCat(id, category) {}

export function productByUseForCat(useFor, category) {
  let cat = category.toLowerCase();
  let response = products.filter((pro) => pro.useFor === useFor && pro.category === cat);
  return response;
}
